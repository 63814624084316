import {ErrorBoundary} from "@stefanprobst/next-error-boundary";
import {Hydrate} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {App as AntD_App, ConfigProvider as AntD_ConfigProvider, theme as antd_theme} from "antd";
import type {AppProps} from "next/app";
import Head from "next/head";
import {useRouter} from "next/router";
import {SessionProvider} from "next-auth/react";
import React, {useEffect} from "react";
import {ThemeProvider} from "react-jss";

import ErrorResult from "@/components/ErrorResult";
import Layout from "@/components/Layout";
import {useThemActions, useThemeMode} from "@/hooks/useThemeStore";
import {baseTheme, darkTheme, lightTheme} from "@/styles/themes";
import {APIClientProvider} from "@/utils/apiCaller";

import "@/utils/axiosInterceptors";
import "@/utils/consoleError";
import "@/utils/reportWebVitals";

import "antd/dist/reset.css";
import "@/styles/common.scss";

function App({Component, pageProps}: AppProps) {
  const router = useRouter();
  const themeMode = useThemeMode();
  const {load: loadTheme} = useThemActions();

  useEffect(() => {
    loadTheme();
  }, [loadTheme]);

  if (!themeMode) return null;

  const isLight = themeMode === "light";
  const theme = isLight ? lightTheme : darkTheme;
  const themeAlgorithm = isLight ? antd_theme.defaultAlgorithm : antd_theme.darkAlgorithm;

  return (
    <SessionProvider session={pageProps.session}>
      <AntD_ConfigProvider
        theme={{
          token: {
            colorPrimary: baseTheme.primaryColor,
          },
          components: {
            Layout: {
              colorBgHeader: baseTheme.backgroundLightGrayColor,
              colorBgTrigger: "",
            },
          },
          algorithm: [themeAlgorithm],
        }}>
        <AntD_App>
          <ThemeProvider theme={theme}>
            <APIClientProvider>
              <Hydrate state={pageProps.dehydratedState}>
                <Head>
                  <title>OL-Manager</title>
                  <meta name="description" content="OrangeLoops :. Manager" />
                  <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                </Head>
                <Layout>
                  <ErrorBoundary
                    key={router.asPath}
                    fallback={<ErrorResult />}
                    onError={(error, errorInfo) => console.error(error, errorInfo)}>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </Layout>
              </Hydrate>

              <ReactQueryDevtools
                initialIsOpen={false}
                toggleButtonProps={{style: {marginLeft: "8px", marginBottom: "5px"}}}
              />
            </APIClientProvider>
          </ThemeProvider>
        </AntD_App>
      </AntD_ConfigProvider>
    </SessionProvider>
  );
}

export default App;
