/**
 * ALL - Levels including custom levels.
 * DEBUG - Designates fine-grained informational events that are most useful to debug an application.
 * INFO  - Designates informational messages that highlight the progress of the application at coarse-grained level.
 * WARN  - Designates potentially harmful situations.
 * ERROR - Designates error events that might still allow the application to continue running.
 * FATAL - Designates very severe error events that will presumably lead the application to abort.
 * OFF - The highest possible rank and is intended to turn off logging.
 */
export enum LogLevel {
  All = 0,
  Debug = 10,
  Info = 20,
  Warning = 30,
  Error = 40,
  Fatal = 50,
  Off = 100,
}
