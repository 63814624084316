import axios from "axios";
import * as AxiosLogger from "axios-logger";

import {LogLevel} from "@/modules/logger/Types";

if (
  process.env.OL_LOGGER_LOG_LEVEL === LogLevel.All.toString() ||
  process.env.OL_LOGGER_LOG_LEVEL === LogLevel.Debug.toString()
) {
  // axios.interceptors.request.use(AxiosLogger.requestLogger);
  // TODO: https://github.com/axios/axios/issues/5494 workaround
  axios.interceptors.request.use((request) => {
    const result = AxiosLogger.requestLogger(request);

    return {...result, headers: request.headers};
  });
  axios.interceptors.response.use(AxiosLogger.responseLogger);
}
