// https://github.com/ant-design/ant-design/issues/31805
// eslint-disable-next-line
const consoleError = console.error.bind(console);

// eslint-disable-next-line
console.error = (errObj, ...args) => {
  if (process.env.NODE_ENV === "development" && typeof errObj.message === "string" && args.includes("findDOMNode"))
    return;
  consoleError(errObj, ...args);
};

export {};
