import type {DefaultTheme} from "react-jss";

import {baseTheme} from "./base";
import stylesVariables from "@/styles/themes/light.module.scss";
import type {StylesThemeVariables} from "@/types/theme";

export const lightTheme: DefaultTheme = {
  ...baseTheme,
  ...(stylesVariables as StylesThemeVariables),
  isDark: false,
  isLight: true,
  theme: "light",
};
