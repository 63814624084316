import {Button} from "antd";
import {signIn} from "next-auth/react";
import React, {MouseEvent} from "react";
import {createUseStyles} from "react-jss";

import MicrosoftIcon from "@/assets/microsoft.svg";

const useStyles = createUseStyles({
  container: {
    marginTop: "2rem",
    textAlign: "center",
  },
  button: {
    display: "inline-flex",
    alignItems: "center",
  },
  buttonIcon: {
    height: 20,
    width: 20,
  },
  buttonText: {
    marginLeft: 10,
  },
});

const SignInForm = () => {
  const styles = useStyles();

  const handleSignIn = (e: MouseEvent) => {
    e.preventDefault();
    signIn("azure-ad");
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        size="large"
        icon={<MicrosoftIcon className={styles.buttonIcon} />}
        onClick={handleSignIn}>
        <span className={styles.buttonText}>Sign in with Microsoft</span>
      </Button>
    </div>
  );
};

export default SignInForm;
