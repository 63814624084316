import {useIsFetching} from "@tanstack/react-query";
import {Spin} from "antd";

const GlobalLoadingIndicator = ({loading}: {loading?: boolean}) => {
  const isFetching = useIsFetching();

  return loading || (process.env.NODE_ENV === "development" && isFetching) ? (
    <Spin style={{marginBottom: "-5px"}} size="small" />
  ) : null;
};

export default GlobalLoadingIndicator;
