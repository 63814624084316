/**
 * This is the client-side entrypoint for your tRPC API.
 * It's used to create the `api` object which contains the Next.js App-wrapper
 * as well as your typesafe react-query hooks.
 *
 * We also create a few inference helpers for input and output types
 */
import {QueryClient, QueryClientConfig, QueryClientProvider} from "@tanstack/react-query";
import {httpBatchLink, loggerLink} from "@trpc/client";
import {createTRPCReact} from "@trpc/react-query";
import {type inferRouterInputs, type inferRouterOutputs} from "@trpc/server";
import React from "react";
import superjson from "superjson";

import {type AppRouter} from "@/server/api/app";

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use Vercel url
  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

export const apiCaller = createTRPCReact<AppRouter>({
  unstable_overrides: {
    useMutation: {
      async onSuccess(options) {
        await options.originalFn();
        await options.queryClient.invalidateQueries();
      },
    },
  },
});

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      refetchOnReconnect: "always",
      cacheTime: 1000 * 60 * 20, //20 mins
      refetchInterval: false,
      refetchIntervalInBackground: false,
      suspense: false,
      staleTime: 1000 * 60 * 20, //20 mins
    },
    mutations: {
      retry: 0,
    },
  },
};

const queryClient = new QueryClient(queryClientConfig);

const trpcClient = apiCaller.createClient({
  /**
   * Transformer used for data deserialization from the server
   * @see https://trpc.io/docs/data-transformers
   **/
  transformer: superjson,

  /**
   * Links used to determine request flow from client to server
   * @see https://trpc.io/docs/links
   **/
  links: [
    loggerLink({
      enabled: (options) =>
        process.env.NODE_ENV === "development" || (options.direction === "down" && options.result instanceof Error),
    }),
    httpBatchLink({
      url: `${getBaseUrl()}/api/v2/trpc`,
    }),
  ],
});

/**
 * Inference helper for inputs
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>;
/**
 * Inference helper for outputs
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const APIClientProvider = (props: React.PropsWithChildren) => (
  <apiCaller.Provider client={trpcClient} queryClient={queryClient}>
    <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
  </apiCaller.Provider>
);
