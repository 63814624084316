import {useError} from "@stefanprobst/next-error-boundary";
import {Button, Result} from "antd";
import {useRouter} from "next/router";
import React from "react";

const ErrorResult = () => {
  const router = useRouter();
  const {error} = useError();

  const handleClick = () => {
    router.replace("/");
  };

  return (
    <Result
      status="error"
      title="Oops! Something went wrong!"
      subTitle={`An unexpected error has occurred: ${error.message}.`}
      extra={
        <Button type="primary" onClick={handleClick}>
          Back Home
        </Button>
      }
    />
  );
};

export default ErrorResult;
