import {presetDarkPalettes} from "@ant-design/colors";
import type {DefaultTheme} from "react-jss";

import {baseTheme} from "./base";
import stylesVariables from "@/styles/themes/dark.module.scss";
import type {StylesThemeVariables} from "@/types/theme";

export const darkTheme: DefaultTheme = {
  ...baseTheme,
  ...(stylesVariables as StylesThemeVariables),
  isDark: true,
  isLight: false,
  theme: "dark",
  colorPalettes: presetDarkPalettes,
};
