import {presetPalettes} from "@ant-design/colors";
import type {DefaultTheme} from "react-jss";

import stylesVariables from "@/styles/themes/base.module.scss";
import {StylesThemeVariables} from "@/types/theme";

export const baseTheme: DefaultTheme = {
  ...(stylesVariables as StylesThemeVariables),
  isDark: false,
  isLight: false,
  theme: "base",
  colorPalettes: presetPalettes,
};
