import {Modal, Space, Typography} from "antd";
import {format} from "date-fns";
import Image from "next/image";
import React from "react";
import {createUseStyles} from "react-jss";

import logoImage from "@/assets/logo.png";

const useStyles = createUseStyles({
  bodyContainer: {
    alignItems: "center",
  },
  logoImageContainer: {
    width: 176,
  },
  logoImage: {
    cursor: "pointer",
    width: "100%",
    height: "auto",
  },
});

type AboutModalProps = {
  open: boolean;
  onCancel: () => void;
};

const AboutModal: React.FC<AboutModalProps> = (props) => {
  const styles = useStyles();
  const {open, onCancel} = props;

  const commit_sha = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

  return (
    <Modal bodyStyle={{textAlign: "center"}} title="About" footer={null} open={open} onCancel={onCancel}>
      <Space className={styles.bodyContainer} direction="vertical">
        <div className={styles.logoImageContainer}>
          <Image
            className={styles.logoImage}
            src={logoImage}
            alt="OrangeLoops"
            priority
            onClick={() => window.open("https://orangeloops.com", "_blank")}
          />
        </div>
        <Typography.Title style={{margin: 0}} level={3}>
          OL-Manager
        </Typography.Title>
        <Typography.Text>
          <b>Version {process.env.NEXT_PUBLIC_APP_VERSION}</b>
          <br />
          <Typography.Text type="secondary">
            {commit_sha && commit_sha.length >= 7 ? `(${commit_sha.substring(0, 7)})` : ""}
          </Typography.Text>
        </Typography.Text>
        <Typography.Text type="secondary">
          &copy; {format(new Date(), "yyyy")} OrangeLoops. All right reserved.
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default AboutModal;
