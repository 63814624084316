import {
  CalendarOutlined,
  ToolOutlined,
  TeamOutlined,
  HomeOutlined,
  ProjectOutlined,
  FormOutlined,
  ControlOutlined,
  LineChartOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import {Avatar, Dropdown, Layout as AntdLayout, Menu, Space, Typography} from "antd";
import {NextPage} from "next";
import Link from "next/link";
import {useRouter} from "next/router";
import {signOut, useSession} from "next-auth/react";
import {GoogleAnalytics} from "nextjs-google-analytics";
import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import InfoIcon from "@/assets/info.svg";
import MoonIcon from "@/assets/moon.svg";
import PowerIcon from "@/assets/power.svg";
import SunIcon from "@/assets/sun.svg";
import AboutModal from "@/components/AboutModal";
import GlobalLoadingIndicator from "@/components/GlobalLoadingIndicator";
import SignInForm from "@/components/SignInForm";
import {insertIf} from "@/core/Helpers";
import useBreakpoint from "@/hooks/useBreakpoint";
import {useThemActions, useThemeMode} from "@/hooks/useThemeStore";
import {GravatarUtils} from "@/server/integrations/gravatar/Utils";
import {MenuClickEventHandler} from "@/types/antd";
import type {ManagerTheme} from "@/types/theme";

const SIDER_COLLAPSED_KEY = "ui.preferences.sider.collapsed";

const useStyles = createUseStyles(({isLight, normalColor}) => ({
  layout: {
    height: "100vh",
    overflow: "hidden",
  },
  headerContainer: {
    display: "flex",
    height: 48,
    justifyContent: "space-between",
  },
  headerTitle: {
    marginBottom: [0, "!important"],
    cursor: "pointer",
    color: [isLight ? normalColor : "inherit", "!important"],
  },
  userContainer: {
    cursor: "pointer",
  },
  userDataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  userDataText: {
    color: [isLight ? normalColor : "inherit", "!important"],
  },
  userAvatar: {
    borderColor: "lightgray",
    borderWidth: 2,
    borderStyle: "solid",
  },
  userMenuIcon: {
    height: 20,
    width: 20,
  },
  sider: {
    height: "100vh",
  },
  menu: {
    height: "100vh",
  },
  contentContainer: {
    overflowY: "scroll",
    overflowX: "scroll",
  },
}));

const Layout: NextPage<{children: ReactNode}> = ({children}) => {
  const styles = useStyles();
  const theme = useTheme<ManagerTheme>();
  const themeMode = useThemeMode();
  const {toggleMode: toggleThemeMode} = useThemActions();

  const router = useRouter();
  const {data: session, status: sessionStatus} = useSession();
  const user = session?.user;
  const {lg} = useBreakpoint();

  const defaultSiderCollapsed = window.localStorage.getItem(SIDER_COLLAPSED_KEY) === "true";
  const [siderCollapsed, setSiderCollapsed] = useState(defaultSiderCollapsed);

  const [globalLoadingIndicator, setGlobalLoadingIndicator] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [googleAnalyticsEnabled, setGoogleAnalyticsEnabled] = useState(false);

  const avatarUrl = useMemo(() => {
    if (!user?.email) return "";

    const avatarSearchParams = new URLSearchParams({
      size: "40",
      default: `https://www.gravatar.com/avatar/${GravatarUtils.getDefaultEmailHash()}?size=40`,
    });

    const emailHash = GravatarUtils.getEmailHash(user.email);
    return `https://www.gravatar.com/avatar/${emailHash}?${avatarSearchParams.toString()}`;
  }, [user]);

  const isAdministrator = process.env.NODE_ENV === "development" || session?.roles?.isAdministrator;

  const isAdministratorOrOperations =
    process.env.NODE_ENV === "development" || session?.roles?.isAdministrator || session?.roles?.isOperations;

  const isDemo = true; // process.env.OL_MANAGER_DEMO === `true`;

  useEffect(() => {
    window.ol_userId = session?.user?.email ? session.user.email.split("@")[0] : undefined;

    setGoogleAnalyticsEnabled(window.ol_userId !== null && window.ol_userId !== undefined);
  }, [session]);

  useEffect(() => {
    const handleRouteChangeStart = () => setGlobalLoadingIndicator(true);
    const handleRouteChangeComplete = () => setGlobalLoadingIndicator(false);
    const handleRouteChangeError = () => setGlobalLoadingIndicator(false);

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeError);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeError", handleRouteChangeError);
    };
  }, [router.events]);

  const handleUserMenuClick: MenuClickEventHandler = ({key}) => {
    switch (key) {
      case "toggleThemeMode":
        setTimeout(() => toggleThemeMode(), 400);
        break;
      case "about":
        setAboutOpen(true);
        break;
      case "signOut":
        signOut();
        break;
      default:
        break;
    }
  };

  const handleSiderCollapse = (value: boolean) => {
    window.localStorage.setItem(SIDER_COLLAPSED_KEY, value.toString());
    setSiderCollapsed(value);
  };

  const handleTitleClick = () => {
    router.push("/");
  };

  const handleAboutOpenToggle = () => {
    setAboutOpen(!aboutOpen);
  };

  return (
    <>
      {googleAnalyticsEnabled && <GoogleAnalytics trackPageViews />}
      <AntdLayout className={styles.layout}>
        <AntdLayout.Header>
          <Space className={styles.headerContainer}>
            <Space>
              <Typography.Title className={styles.headerTitle} level={lg ? 3 : 5} onClick={handleTitleClick}>
                OL - Manager
              </Typography.Title>
              <GlobalLoadingIndicator loading={globalLoadingIndicator} />
            </Space>
            {session && (
              <Space>
                <Dropdown
                  placement="bottomRight"
                  menu={{
                    items: [
                      {
                        key: "toggleThemeMode",
                        icon:
                          themeMode === "light" ? (
                            <MoonIcon className={styles.userMenuIcon} />
                          ) : (
                            <SunIcon className={styles.userMenuIcon} />
                          ),
                        label: themeMode === "light" ? "Dark mode" : "Light mode",
                      },
                      {
                        key: "about",
                        icon: <InfoIcon className={styles.userMenuIcon} />,
                        label: "About",
                      },
                      {type: "divider"},
                      {
                        key: "signOut",
                        icon: <PowerIcon className={styles.userMenuIcon} />,
                        label: "Sign out",
                      },
                    ],
                    style: {width: "max-content"},
                    onClick: handleUserMenuClick,
                  }}
                  trigger={["click"]}>
                  <Space className={styles.userContainer}>
                    <div className={styles.userDataContainer}>
                      <Typography.Text className={styles.userDataText} strong>
                        {session.user.name}
                      </Typography.Text>
                      <Typography.Text className={styles.userDataText}>
                        {session.user.email.split("@")[0]}
                      </Typography.Text>
                    </div>
                    <Avatar className={styles.userAvatar} size={38} src={avatarUrl} alt={session.user.name} />
                  </Space>
                </Dropdown>
              </Space>
            )}
          </Space>
          <AboutModal open={aboutOpen} onCancel={handleAboutOpenToggle}></AboutModal>
        </AntdLayout.Header>
        <AntdLayout hasSider>
          {session && (
            <AntdLayout.Sider
              className={styles.sider}
              collapsible
              defaultCollapsed={defaultSiderCollapsed}
              collapsed={siderCollapsed}
              onCollapse={handleSiderCollapse}>
              <Menu
                className={styles.menu}
                mode="inline"
                theme={theme.isLight ? "dark" : undefined}
                items={[
                  {key: "/", icon: <HomeOutlined />, label: "Home"},
                  {key: "/private/people", icon: <TeamOutlined />, label: "People"},
                  {
                    key: "/private/assignments",
                    icon: <CalendarOutlined />,
                    label: "Assignments",
                  },
                  {key: "/private/projects", icon: <ProjectOutlined />, label: "Projects"},
                  ...insertIf(!!(isAdministrator && !isDemo), {
                    key: "/private/v2/projects",
                    icon: <ProjectOutlined />,
                    label: "Projects v2",
                  }),
                  ...insertIf(isAdministratorOrOperations, {
                    key: "/private/v2/reports",
                    icon: <LineChartOutlined />,
                    label: "Reports",
                    children: [
                      ...insertIf(isAdministratorOrOperations, {
                        key: "/private/v2/reports/hours",
                        icon: <FieldTimeOutlined />,
                        label: "Hours",
                      }),
                    ],
                  }),
                  ...insertIf(isAdministratorOrOperations, {
                    key: "/private/administration",
                    icon: <ControlOutlined />,
                    label: "Administration",
                    children: [
                      ...insertIf(isAdministrator && !isDemo, {
                        key: "/private/forms",
                        icon: <FormOutlined />,
                        label: "Forms",
                      }),
                      ...insertIf(isAdministratorOrOperations, {
                        key: "/private/tools",
                        icon: <ToolOutlined />,
                        label: "Tools",
                      }),
                    ],
                  }),
                ]}
                defaultOpenKeys={!siderCollapsed ? ["/private/v2/reports", "/private/administration"] : undefined}
                defaultSelectedKeys={["/"]}
                selectedKeys={[router.pathname]}
                _internalRenderMenuItem={(originNode, menuItemProps) => (
                  <Link href={menuItemProps.eventKey} legacyBehavior>
                    {originNode}
                  </Link>
                )}
              />
            </AntdLayout.Sider>
          )}

          <AntdLayout>
            <AntdLayout.Content className={styles.contentContainer}>
              {sessionStatus !== "loading" && !session && <SignInForm />}
              {session && children}
            </AntdLayout.Content>
          </AntdLayout>
        </AntdLayout>
      </AntdLayout>
    </>
  );
};

export default Layout;
