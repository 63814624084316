import {create} from "zustand";

const THEME_MODE_KEY = "ui.preferences.themeMode";
export const THEME_MODE_DEFAULT = "light";

interface ThemeModeState {
  mode: string | null;
  actions: {
    load: () => void;
    setMode: (value: string) => void;
    toggleMode: () => void;
  };
}

const useThemeStore = create<ThemeModeState>()((set, get) => ({
  mode: null,
  actions: {
    load: () => {
      let value = get().mode;

      if (!value) {
        if (typeof document !== "undefined") {
          value = document.documentElement.getAttribute("data-theme");
        }

        if (!value) {
          try {
            value = localStorage.getItem(THEME_MODE_KEY);
          } catch (error) {}

          if (!value) {
            const preferDarkQuery = "(prefers-color-scheme: dark)";
            const mediaQueryList = window.matchMedia(preferDarkQuery);
            const supportsColorSchemeQuery = mediaQueryList.media === preferDarkQuery;

            if (supportsColorSchemeQuery) {
              value = mediaQueryList.matches ? "dark" : "light";
            } else {
              value = THEME_MODE_DEFAULT;
            }
          }
        }

        document.documentElement.setAttribute("data-theme", value);
        set(() => ({mode: value}));
      }
    },
    setMode: (value) => {
      document.documentElement.setAttribute("data-theme", value);
      window.localStorage.setItem(THEME_MODE_KEY, value);

      set(() => ({mode: value}));
    },
    toggleMode: () => {
      const value = get().mode === "light" ? "dark" : "light";

      document.documentElement.setAttribute("data-theme", value);
      window.localStorage.setItem(THEME_MODE_KEY, value);

      set(() => ({mode: value}));
    },
  },
}));

export const useThemeMode = () => useThemeStore((state) => state.mode);
export const useThemActions = () => useThemeStore((state) => state.actions);
